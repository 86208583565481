import {Dialog, Transition} from '@headlessui/react';
import {Fragment, useRef, useState} from 'react';
import {Cart as CartType} from '@shopify/hydrogen/storefront-api-types';
import {Suspense} from 'react';
import {Await} from '@remix-run/react';
import {LineItems} from './LineItems';
import Summary from './Summary';
import {CrossIcon} from '~/assets/Icons';
import {CartForm, flattenConnection} from '@shopify/hydrogen';
import Checkout from './Checkout';
import meemImage from '../../assets/meem.png';
import {Image} from '@shopify/hydrogen';
import {Link} from '@remix-run/react';
import ChatBubbles from '../ChatBubbles';
import {FAVOURITES_COLLECTION} from '~/consts';
/**
 * A Drawer component that opens on user click.
 * @param open - Boolean state. If `true`, then the drawer opens.
 * @param onClose - Function should set the open state.
 */

export interface DrawerProps {
  open: boolean;
  onClose: () => void;
  cart: Promise<CartType>;
}

export interface DrawerControls {
  isOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const ENTER_STYLE = 'translate-x-0 opacity-100';
const LEAVE_STYLE = 'translate-x-20 opacity-0';
const TRANSITION_STYLE = 'transform transition ease-in-out duration-500';

function Drawer({open, onClose, cart}: DrawerProps) {
  const [addressForm, setAddressForm] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  function isValidEthereumAddress(address) {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  }
  const formRef = useRef(null);
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[999] inset-0 overflow-hidden"
        onClose={() => {
          onClose();
          setAddressForm(null);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter={TRANSITION_STYLE}
          enterFrom={LEAVE_STYLE}
          enterTo={ENTER_STYLE}
          leave={TRANSITION_STYLE}
          leaveFrom={ENTER_STYLE}
          leaveTo={LEAVE_STYLE}
        >
          <div className="fixed inset-0 w-screen overflow-y-auto">
            <Dialog.Panel className="h-full md:h-auto container w-screen">
              <Suspense>
                <Await resolve={cart}>
                  {(data) => (
                    <div className="relative">
                      <div className="absolute shadow-lg w-full sm:w-[440px] text-night-600 right-0 top-4 sm:top-[96px] md:top-[120px] p-4 xl:p-8 border-2 border-honey-500 rounded-2xl bg-honey-200">
                        <div className="space-y-2 xl:space-y-8 h-full mb-8">
                          <div className="w-full flex items-center justify-between p-3 lg:p-0">
                            <div className="flex items-center gap-2">
                              <h1 className="text-2xl xl:text-3xl font-medium text-primary">
                                Cart
                              </h1>
                              <p className="px-4 py-1 h-max font-medium text-background text-sm rounded-full bg-ruby">
                                {data ? data?.totalQuantity : 0}
                              </p>
                            </div>
                            <button
                              onClick={onClose}
                              className="p-3 xl:p-4 hover:bg-primary hover:border-ruby-900 text-night-600 transition-colors hover:text-background  border border-night-600 flex items-center justify-center rounded-full"
                            >
                              <CrossIcon className="w-2 xl:w-2.5 " />
                            </button>
                          </div>
                          <div className="h-[400px] sm:h-[calc(100%-64px)">
                            {data && data?.totalQuantity > 0 ? (
                              <LineItems
                                lines={flattenConnection(data.lines)}
                              />
                            ) : (
                              <div className="flex-col h-full flex justify-end sm:py-16 space-y-8 translate-y-[15%]">
                                <ChatBubbles
                                  messages={[
                                    'Hmm... It seems your cart is empty!',
                                    <p key="second">
                                      Checkout some of{' '}
                                      <Link
                                        to={`/collection/${FAVOURITES_COLLECTION}`}
                                        className="underline hover:text-sapphire-700 transition-colors"
                                      >
                                        my favourites
                                      </Link>
                                    </p>,
                                  ]}
                                />
                                <img
                                  src={meemImage}
                                  alt="Meem the shopkeeper"
                                  className="w-[240px] "
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pb-4">
                          {addressForm ? (
                            <div>
                              <p className="text-sm text-night-600 pb-2">
                                Your ETH Address:
                              </p>
                              <div className="flex gap-2">
                                <p className="h-10 border flex-1 border-night-300 rounded-md px-2 flex items-center">
                                  {addressForm.slice(0, 6) +
                                    '...' +
                                    addressForm.slice(-6)}
                                </p>
                                <button
                                  onClick={() => setAddressForm(null)}
                                  className="border-night-300 border text-night-700 px-4 py-2 rounded-md ml-2 transition-colors  "
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ) : (
                            <CartForm
                              route="/cart"
                              action={CartForm.ACTIONS.NoteUpdate}
                            >
                              <p className="text-sm text-night-600 pb-2">
                                Enter your ETH Address
                              </p>
                              <div className="flex gap-2">
                                <input
                                  type="text"
                                  name="note"
                                  ref={formRef}
                                  className="h-10 border flex-1 border-night-300 rounded-md px-2 flex items-center"
                                />
                                <button
                                  onClick={() => {
                                    if (
                                      isValidEthereumAddress(
                                        formRef?.current?.value,
                                      )
                                    ) {
                                      setAddressForm(formRef?.current?.value);
                                      setError(null);
                                    } else {
                                      setError('Invalid Ethereum Address');
                                    }
                                  }}
                                  className="bg-primary text-background px-4 py-2 rounded-md ml-2 hover:bg-ruby-900 transition-colors hover:text-background"
                                >
                                  Save
                                </button>
                              </div>
                              {error && (
                                <p className="text-sm pt-1 text-ruby-900">
                                  {error}
                                </p>
                              )}
                            </CartForm>
                          )}
                        </div>
                        <div>
                          <div className="border border-night-400 rounded-xl overflow-hidden ">
                            <Summary cost={data?.cost} />
                          </div>
                          <Checkout
                            className="mt-6"
                            checkoutUrl={data?.checkoutUrl}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Await>
              </Suspense>
            </Dialog.Panel>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}
/* Use for associating arialabelledby with the title*/
Drawer.Title = Dialog.Title;
export {Drawer};
export function useDrawer(openDefault = false) {
  const [isOpen, setIsOpen] = useState(openDefault);
  function openDrawer() {
    setIsOpen(true);
  }
  function closeDrawer() {
    setIsOpen(false);
  }
  return {
    isOpen,
    openDrawer,
    closeDrawer,
  };
}
