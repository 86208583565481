import React from 'react';
import {TreasureLogo} from '~/assets/Logos';
import {Link} from '@remix-run/react';
import {
  DiscordIcon,
  ExternalIcon,
  TwitchIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../assets/Icons';
import {
  DISCORD_URL,
  TWITTER_URL,
  TREASURE_URL,
  PLATFORM_URL,
  YOUTUBE_URL,
  TWITCH_URL,
} from '~/consts';
import {Button} from './primitives/Button';
import {CutOffSvg} from '~/assets/Svgs';
import {twMerge} from 'tailwind-merge';

const Links = [
  {
    title: 'Help',
    links: [
      {
        title: 'Contact',
        url: '/contact',
        external: false,
      },
      {
        title: 'Shipping & Returns',
        url: '/shipping',
        external: false,
      },
      {
        title: 'FAQs',
        url: '/FAQ',
        external: false,
      },
    ],
  },
  {
    title: 'Discover',
    links: [
      {
        title: 'Treasure',
        url: TREASURE_URL,
        external: true,
      },
      {
        title: 'Platform',
        url: PLATFORM_URL,
        external: true,
      },
      {
        title: 'Games',
        url: '/Games',
        external: true,
      },
    ],
  },
];

const Footer = ({className}: {className: string}) => {
  return (
    <div className="container container-bottom">
      <section
        className={twMerge(
          'bg-night-1300 text-background relative rounded-2xl w-full p-8 md:p-20 space-y-12',
          className,
        )}
      >
        <div className="flex justify-between gap-12 md:flex-row flex-col">
          <div className="space-y-4 md:space-y-16">
            <a href={TREASURE_URL} target="_blank" rel="noreferrer">
              <TreasureLogo className="h-8 md:h-10 " />
            </a>
            <h1 className="leading-[110%] text-3xl md:text-4xl font-heading  whitespace-nowrap">
              The magic <br /> of play.
            </h1>
          </div>
          <div className="flex flex-col md:flex-row gap-12 md:gap-0">
            <div
              className={`grid grid-cols-2 gap-12 md:gap-24 md:grid-cols-${Links.length}`}
            >
              {Links.map((link) => (
                <ul className="space-y-3" key={link.title}>
                  <p className="font-medium">{link.title}</p>
                  <div className="space-y-2">
                    {link.links.map((link) => (
                      <li
                        className="opacity-60 flex transition-opacity items-center gap-1 hover:opacity-100"
                        key={link.title}
                      >
                        <Link className="" to={link.url}>
                          {link.title}
                        </Link>
                        {link.external && (
                          <ExternalIcon className="w-4 opacity-50" />
                        )}
                      </li>
                    ))}
                  </div>
                </ul>
              ))}
            </div>
            <div className="space-y-8">
              <div className="space-y-4">
                <Button
                  mode="ruby"
                  className="w-full"
                  href="https://app.treasure.lol/"
                >
                  Start Playing
                </Button>
                <Button
                  mode="honey"
                  className="w-full md:w-max"
                  href="https://treasure.lol/build"
                >
                  Start Building
                </Button>
              </div>
              <div className="flex items-center justify-end gap-3">
                <Link to={DISCORD_URL} target="_blank" rel="noreferrer">
                  <DiscordIcon className="w-6 opacity-100 hover:opacity-70 transition-opacity duration-300 ease-in-out" />
                </Link>
                <Link to={TWITTER_URL} target="_blank" rel="noreferrer">
                  <TwitterIcon className="w-6 opacity-100 hover:opacity-70 transition-opacity duration-300 ease-in-out" />
                </Link>
                <Link to={YOUTUBE_URL} target="_blank" rel="noreferrer">
                  <YoutubeIcon className="w-6 opacity-100 hover:opacity-70 transition-opacity duration-300 ease-in-out" />
                </Link>
                <Link to={TWITCH_URL} target="_blank" rel="noreferrer">
                  <TwitchIcon className="w-6 opacity-100 hover:opacity-70 transition-opacity duration-300 ease-in-out" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 justify-between pt-6 border-t border-[color-mix(in_srgb,_currentColor_20%,_transparent)] border-opacity-5 flex-col md:flex-row">
          <div className="flex gap-2">
            <Link
              to="/tos"
              className="opacity-60 hover:opacity-100 transition-opacity"
            >
              Terms of Service
            </Link>
            <Link
              to="/privacy"
              className="opacity-60 hover:opacity-100 transition-opacity"
            >
              Privacy Policy
            </Link>
          </div>
          <p className="opacity-50 text-sm md:text-md text-center md:text-start">
            © 2023-2024 Treasure Technology Foundation
          </p>
        </div>
        <CutOffSvg className="absolute -bottom-[2px] -right-[1px] w-[80px] sm:w-[96px]" />
      </section>
    </div>
  );
};

export default Footer;
