import {Link} from '@remix-run/react';
import {CartIcon, ChevronDownIcon, UserIcon, SearchIcon} from '../assets/Icons';
import {TreasureLogoFlat} from '~/assets/Logos';
import {Cart as CartType} from '@shopify/hydrogen/storefront-api-types';
import {Suspense, useEffect, useState} from 'react';
import {Await} from '@remix-run/react';
import {DrawerControls} from './cart/CartDrawer';
import {useLocation} from '@remix-run/react';
import meemImage from '../assets/meem_icon.png';
import {twMerge} from 'tailwind-merge';
import {BUNDLE_HANDLE, FAVOURITES_COLLECTION} from '~/consts';
import {useMotionValueEvent, useScroll} from 'framer-motion';

interface Props {
  cart: Promise<CartType>;
  drawerControls: DrawerControls;
  className?: string;
}

const DEV = false;

const Navigation = ({cart, drawerControls, className}: Props) => {
  const {isOpen, openDrawer, closeDrawer} = drawerControls;
  const {pathname} = useLocation();
  useEffect(() => {
    if (pathname === '/cart') {
      closeDrawer();
    }
  }, [pathname]);

  const [menuVisible, setMenuVisible] = useState(true);
  const [latestScroll, setLatestScroll] = useState(0);
  const {scrollY} = useScroll();
  const scrollDelay = 75;

  useMotionValueEvent(scrollY, 'change', (latest: number) => {
    if (latest - scrollDelay > latestScroll) {
      setMenuVisible(false);
    } else if (latest + scrollDelay < latestScroll) {
      setMenuVisible(true);
    }
    setLatestScroll(latest);
  });

  const isOnCartPage = pathname === '/cart';

  return (
    <Suspense>
      <Await resolve={cart}>
        {(data: any) => (
          <div className="fixed w-screen inset-x-0 top-0 z-50 pt-4 md:pt-6">
            <div className="container sm:-translate-x-1.5">
              <div
                className={twMerge(
                  'bg-primary transition-opacity text-background px-6 h-16 md:h-20 flex items-center justify-between rounded-2xl',
                  !menuVisible && 'pointer-events-none opacity-0',
                  className,
                )}
              >
                <div className="flex items-center gap-10">
                  <Link to="/">
                    <TreasureLogoFlat className="h-6 md:h-8" />
                  </Link>
                  {DEV && (
                    <div>
                      <div className="block sm:hidden">Mobile</div>
                      <div className="hidden sm:block md:hidden">SM</div>
                      <div className="hidden md:block lg:hidden">MD</div>
                      <div className="hidden lg:block xl:hidden">LG</div>
                      <div className="hidden xl:block 2xl:hidden">XL</div>
                      <div className="hidden 2xl:block">2xl</div>
                    </div>
                  )}
                  {/* <div className="flex gap-4 font-medium">
                    <Link to="/collections" className="hidden md:flex">
                      Collections
                    </Link>
                    <Link
                      to={`/products/${BUNDLE_HANDLE}`}
                      className="hidden md:flex"
                    >
                      Genesis Pack
                    </Link>
                    <Link
                      to={`/products/${FAVOURITES_COLLECTION}`}
                      className="hidden md:flex"
                    >
                      Featured Merch
                    </Link>
                  </div> */}
                </div>
                <div className="flex">
                  <button
                    className="relative"
                    onClick={openDrawer}
                    disabled={isOnCartPage}
                  >
                    {data && data?.totalQuantity > 0 && (
                      <div className="absolute top-0 right-0 aspect-square rounded-full bg-background w-5 text-sm text-primary -translate-x-1/2 -translate-y-1/2">
                        <span>{data?.totalQuantity}</span>
                      </div>
                    )}
                    <CartIcon
                      className={twMerge(
                        'w-5 md:w-6',
                        isOnCartPage && 'opacity-50',
                      )}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Await>
    </Suspense>
  );
};

export default Navigation;
