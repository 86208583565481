import {ReactNode, useEffect} from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import {useLocation} from '@remix-run/react';
import {Cart} from '@shopify/hydrogen/storefront-api-types';
import {Drawer, useDrawer} from './cart/CartDrawer';
import {useFetchers} from '@remix-run/react';
import {CartForm} from '@shopify/hydrogen';
import {twMerge} from 'tailwind-merge';

interface Props {
  children: ReactNode;
  cart: Promise<Cart>;
  title: string;
}

export const Layout = ({children, cart, title}: Props) => {
  const {pathname} = useLocation();
  const {isOpen, openDrawer, closeDrawer} = useDrawer();
  const fetchers = useFetchers();
  const addToCartFetchers = [];
  for (const fetcher of fetchers) {
    const formData = fetcher.submission?.formData;
    if (formData) {
      const formInputs = CartForm.getFormInput(formData);
      if (formInputs.action === CartForm.ACTIONS.LinesAdd) {
        addToCartFetchers.push(fetcher);
      }
    }
  }

  const INVERT_COLORS = ['bundle'].includes(pathname.split('/')[1]);

  // When the fetchers array changes, open the drawer if there is an add to cart action
  useEffect(() => {
    if (isOpen || addToCartFetchers.length === 0) return;
    openDrawer();
  }, [addToCartFetchers]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant', // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return (
    <div
      className={twMerge(
        'max-w-screen overflow-x-hidden pt-20 md:pt-[104px] bg-background',
        INVERT_COLORS && 'bg-primary',
      )}
    >
      <div className="flex flex-col min-h-screen antialiased relative">
        {!(pathname === '/cart') && (
          <Drawer open={isOpen} onClose={closeDrawer} cart={cart} />
        )}
        <Navigation
          cart={cart}
          drawerControls={{isOpen, openDrawer, closeDrawer}}
          className={INVERT_COLORS ? 'bg-background text-primary' : ''}
        />
        <main role="main" id="mainContent">
          {children}
        </main>
      </div>
      <Footer className={INVERT_COLORS ? 'bg-background text-primary' : ''} />
    </div>
  );
};
