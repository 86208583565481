import {
  type LinksFunction,
  type LoaderArgs,
  type V2_MetaFunction,
} from '@shopify/remix-oxygen';
import {Links, Meta, Outlet, Scripts, useLoaderData} from '@remix-run/react';
import styles from './styles/app.css';
import favicon from '../public/favicon.svg';
import {MotionGlobalConfig} from 'framer-motion';
import {json} from '@shopify/remix-oxygen';
import {CartForm} from '@shopify/hydrogen';
import {invariant} from 'framer-motion';
import {defer} from '@shopify/remix-oxygen';
import {Layout} from './components/Layout';

MotionGlobalConfig.useManualTiming = true;

export const links: LinksFunction = () => {
  return [
    {rel: 'stylesheet', href: styles},
    {
      rel: 'preconnect',
      href: 'https://cdn.shopify.com',
    },
    {
      rel: 'preconnect',
      href: 'https://shop.app',
    },
    {rel: 'icon', type: 'image/svg+xml', href: favicon},
  ];
};

export const meta: V2_MetaFunction = () => [
  {
    title: 'Treasure Shop',
  },
  {
    name: 'description',
    content: 'Official Treasure shop & exclusive merchandise',
  },
  {
    property: 'og:title',
    content: 'Treasure Shop',
  },
  {
    property: 'og:image',
    content: '/img/seo_banner.jpg',
  },
];

export async function loader({context, request}: LoaderArgs) {
  const {cart} = context;
  return defer({
    cart: cart.get(),
    layout: await context.storefront.query(LAYOUT_QUERY),
  });
}

export async function action({request, context}: LoaderArgs) {
  const {cart} = context;
  const formData = await request.formData();
  const {action, inputs} = CartForm.getFormInput(formData);
  let result;
  switch (action) {
    case CartForm.ACTIONS.LinesAdd:
      result = await cart.addLines(inputs.lines);
      break;
    case CartForm.ACTIONS.LinesUpdate:
      result = await cart.updateLines(inputs.lines);
      break;
    case CartForm.ACTIONS.LinesRemove:
      result = await cart.removeLines(inputs.lineIds);
      break;
    default:
      invariant(false, `${action} cart action is not defined`);
  }
  // The Cart ID might change after each mutation, so update it each time.
  const headers = cart.setCartId(result ? result?.cart.id : '');
  return json(result, {status: 200, headers});
}

// function TokenGatingLogic() {
//   const [timeLeft, setTimeLeft] = useState([0, 0, 1, 3, 3, 7]);
//   const {wallet} = useConnectWallet();

//   useEffect(() => {
//     const releaseDate = new Date('2024-02-22T00:00:00');
//     const interval = setInterval(() => {
//       const now = new Date();
//       const diffInSeconds = Math.floor(
//         (releaseDate.getTime() - now.getTime()) / 1000,
//       );

//       const hours = Math.floor(diffInSeconds / 3600);
//       const minutes = Math.floor((diffInSeconds % 3600) / 60);
//       const seconds = diffInSeconds % 60;

//       setTimeLeft([
//         Math.floor(hours / 10),
//         hours % 10,
//         Math.floor(minutes / 10),
//         minutes % 10,
//         Math.floor(seconds / 10),
//         seconds % 10,
//       ]);
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const {data: tokens = []} = useQuery(
//     ['inventory', wallet?.address],
//     () =>
//       fetchTroveInventory({
//         address: wallet?.address ?? zeroAddress,
//         collectionAddresses: ['0xa7f1462e0ecdeebdee4faf6681148ca96db78777'],
//       }),
//     {
//       enabled: !!wallet,
//     },
//   );

//   if (tokens.length > 0 || timeLeft.every((nr) => nr === 0)) {
//     return <Outlet />;
//   }

//   return <TokenGating timeLeft={timeLeft} />;
// }

export default function App() {
  const data = useLoaderData<typeof loader>();
  const {name} = data.layout.shop;
  const {cart} = data;

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Layout title={name} cart={cart}>
          <Outlet />
        </Layout>
      </body>
      <Scripts />
    </html>
  );
}

const LAYOUT_QUERY = `#graphql
  query layout {
    shop {
      name
      description
    }
  }
`;
