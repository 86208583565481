import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {TriangleSvg} from '~/assets/Svgs';
import {twMerge} from 'tailwind-merge';

interface Props {
  messages: React.ReactNode[];
}

const delay = 0.6;

const ChatBubbles = ({messages}: Props) => {
  return (
    <AnimatePresence>
      <div
        className={twMerge(
          'flex flex-col gap-3 align-bottom justify-end',
          `h-[${messages.length * 44}px]`,
        )}
      >
        {messages.map((message: React.ReactNode, index) => (
          <motion.div
            className="relative"
            key={index}
            initial={{opacity: 0, y: 20}}
            animate={{opacity: 1, y: 0}}
            transition={{delay: index * delay + 0.2}}
          >
            <p className="px-4 w-max py-2 leading-[160%] bg-[white] text-night-600 border border-night-300 rounded-full">
              {message}
            </p>
            <div className="absolute -bottom-[11px] left-5">
              <motion.div
                initial={{opacity: 1}}
                animate={{
                  opacity:
                    messages.length > 1 && index < messages.length - 1 ? 0 : 1,
                }}
                transition={{delay: index * delay + 0.7}}
              >
                <TriangleSvg />
              </motion.div>
            </div>
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  );
};

export default ChatBubbles;
